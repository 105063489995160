import React from "react";
import "./Login.css";
import { getServerHost } from "../../services/url.service";
import logo from "../../assest/login-logo.svg";

const Login = () => {
  const href = `${getServerHost()}/api/auth/google/sign-in`;

  return (
    <div>
      <div className="Login-container">
        <div className="Login-semi-container">
          <h1 className="first-box-central-name">CENTRAL</h1>
          <img src={logo} alt="#" className="logo-img" />
          <div className="sign-in-button">
            <a href={href}>
              <button>Sign-In with Google</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
