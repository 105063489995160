import React from "react";
import Day from "./Day";
import { Grid } from "@mui/material";
import {} from "@mui/material/Grid";
import Box from "@mui/material/Box";

const Month = ({ currentWeek, user }) => {
  return (
    <Box display="flex" flex="1" sx={{}}>
      <Grid container>
        {currentWeek?.map((row, i) => (
          <Grid
            item
            key={i}
            style={{
              flex: "1 0 14.28%",
              border: "1px solid #B8B8B8",
              height:"60vh",
              overflowY:"auto"
            }}
          >
            <Day day={row} rowIdx={i} user={user} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Month;
