import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchApi } from "../../services/api.service";
import "./Xero.css";
// import { saveAs } from "file-saver";
// const axios = require("axios");

function Xero() {
  const navigate = useNavigate();
  const location = useLocation();
  const splitBillDetails = location.state.splitBill;
  console.log(splitBillDetails);

  const handleTestXeroApi = async () => {
    let response = await fetchApi("/api/xero/organisation");
    response = await response.json();
    console.log(response);
    if (!response.success) {
      alert(response.message);
    }
  };

  function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleCreateInvoice = async () => {
    let response = await fetchApi(
      `/api/xero/invoices?invoiceid=${splitBillDetails.id}`
    );
    response = await response.json();
    console.log(response);
    if (response.success) {
      alert("invoice sent to xero");
    } else if (!response.success) {
      alert(response.error);
    }
    navigate("/home/readytosendpayinvtoxero");
  };

  const handleGetInvoiceAsPdf = async () => {
    // const xtid = "232352a9-3ece-4442-b360-37aa286becfc";
    // const type = "ACCPAY";
    let response = await fetchApi("/api/xero/invoicepdf");
    console.log(response);
    response = await response.json();
    console.log(response.toString());

    // fetchApi(
    //   `/api/xero/invoicepdf`
    //   // { Headers: { accept: "application/pdf" } }
    // )
    //   .then(async (response) => {
    //     console.log(response);
    //     // const responsePdf = await api.getPDF(uuid);
    //     const blob = new Blob([response.data], { type: "application/pdf" });
    //     window.open(URL.createObjectURL(blob));
    //     // response.blob().then((blob) => download(blob));
    //     // const fileBlob = await response.blob();
    //     // console.log("response", response);
    //     // // const fileBlob=new Blob([responseJson.payload],{type:"application/pdf"});
    //     // const url = window.URL.createObjectURL(fileBlob);
    //     // // window.open(url);
    //     // // console.log(fileBlob,url);
    //     // const link = document.createElement("a");
    //     // link.href = url;
    //     // link.setAttribute("download", "inv.pdf");
    //     // link.click();
    //   })
    //   .catch((error) => {
    //     console.log("Error:", error);
    //   });

    // fetchApi(`/api/xero/invoicepdf`)
    //   .then((response) => {
    //     console.log(response);
    //     response.blob();
    //   })
    //   .then((blob) => {
    //     // Create a temporary URL for the blob
    //     const url = URL.createObjectURL(blob);

    //     // Create a link element and simulate a click to trigger the download
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = "invoice.pdf";
    //     document.body.appendChild(link);
    //     link.click();

    //     // Remove the link element
    //     document.body.removeChild(link);
    //   })
    //   .catch((error) => {
    //     console.log("Error:", error);
    //   });
  };

  /**
   * Get consent URL to sign into Xero
   * * Request server for the consent URL then redirect to it.
   */
  const handleConnectToXero = async () => {
    let response = await fetchApi(`/api/xero/connect`);
    response = await response.json();
    window.open(response.consentUrl, "_blank");
  };

  return (
    <div className="xero-box1">
      {/* <p>Sign in to Xero with a Xero admin account.</p>
      <p>In order to get credentials for subsequent Xero API calls.</p>
      <p>
        If not yet saving to the database: You must re-connect every time the
        server restarts
      </p>
      <p>
        If saving to the database: You must re-connect if the credentials are
        not stored yet, or if the tokens have expired.
      </p> */}
      <button onClick={handleConnectToXero} className="Button-connect-to-xero">
        Connect To Xero
      </button>

      {/* <p>Afterwards, you may use the Xero API without signing in.</p> */}
      {/* <button onClick={handleTestXeroApi}>
        Test Xero API 
      </button> */}

      {/* <p>Send the invoice</p> */}
      <button onClick={handleCreateInvoice} className="Button-send-invoice">
        Send Invoice
      </button>
    </div>
  );
}

export default Xero;
