import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../TimesheetAssets/logo.png";
import "./CalenderHeader.css";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import dayjs from "dayjs";
import GlobalContext from "../TimesheetContext/GlobalContext";
import { getWeek } from "../util/util";
import {
  AddCurrentTimeStamp,
  getTask,
  getUserTasks,
} from "../../services/Currency.service";
import { IconButton } from "@mui/material";

const CalendarHeader = ({ user }) => {
  const { dayIndex, setDayIndex } = useContext(GlobalContext);

  //Timer
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const timeRef = useRef(null);
  const [savedEvents, setSavedEvents] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [taskId, setTaskId] = useState(-1);

  const [strtTime, setStrtTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  useEffect(() => {
    let timerInterval;

    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setElapsedTime(new Date().getTime() - strtTime);
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [isTimerRunning, strtTime]);

  // useEffect(() => {
  //   if (isRunning) {
  //     timeRef.current = setInterval(() => {
  //       setTime((pervTime) => pervTime + 1);
  //     }, 1000);
  //   } else {
  //     clearInterval(timeRef.current);
  //   }
  //   return () => {
  //     clearInterval(timeRef.current);
  //   };
  // }, [isRunning]);

  const handleStart = () => {
    // if (!isRunning) {
    //   setIsRunning(true);
    // }

    if (!isTimerRunning) {
      setStrtTime(new Date().getTime() - elapsedTime);
      setIsTimerRunning(true);
    }

    var timestamp = new Date();
    var time =
      timestamp.getHours() +
      ":" +
      timestamp.getMinutes() +
      ":" +
      timestamp.getSeconds();
    setStartTime(time);
    console.log("Timestamp-start", time);
  };

  const handleStop = async () => {
    // if (isRunning) {
    //   setIsRunning(false);
    // }

    if (isTimerRunning) {
      setIsTimerRunning(false);
    }

    var timestamp = new Date();
    var time =
      timestamp.getHours() +
      ":" +
      timestamp.getMinutes() +
      ":" +
      timestamp.getSeconds();
    // setEndTime(time);
    console.log("Timestamp-stop", time);
    const data = {
      start_time: startTime,
      end_time: time,
      task_id: taskId,
    };

    CurrentTimeStamp(data);
  };

  const formatTime = (time) => {
    return time < 10 ? "0" + time : time;
  };

  const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
  const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

  const CurrentTimeStamp = async (data) => {
    await AddCurrentTimeStamp(data);
  };

  const handleRestart = (e) => {
    setTime(0);
    if (isTimerRunning) {
      clearInterval(timeRef.current);
      timeRef.current = setInterval(() => {
        setTime((revTime) => revTime + 1);
      }, 1000);
    }
    setTaskId(e.target.value);
  };
  //.......

  const handlePrevWeek = () => {
    console.log(dayIndex.subtract(7, "day"));
    setDayIndex(dayIndex.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setDayIndex(dayIndex?.add(7, "day"));
  };

  const fetchTask = async () => {
    // let data = await getUserTasks(user?.id);
    // setSavedEvents(data);
    // console.log(".....", data);
  };

  useEffect(() => {
    fetchTask().then(() => { });
  }, []);

  return (
    <div className="calender-main-container">
      <header className="calendar-header">
        {/* <img src={logo} alt="calendar" className="calendar-logo" /> */}
        <h1 className="calendar-title">Calendar</h1>
        <button className="calendar-button">Today</button>
        <IconButton onClick={handlePrevWeek}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton onClick={handleNextWeek}>
          <ChevronRightIcon />
        </IconButton>
        <h3 className="date">
          {dayjs(
            new Date(dayIndex.year(), dayIndex.month(), dayIndex.date())
          ).format("MMMM YYYY")}
        </h3>
      </header>

      {/* <div className="project-timer-div">
        <div className="project-timer-div-1">
          <select
            onChange={(e) => {
              handleRestart(e);
            }}
          >
            <option>Select Task</option>
            {savedEvents
              ?.filter((elem) => elem?.day === dayjs().format("MM-DD-YYYY"))
              ?.map((elem, id) => (
                <option key={id} value={elem.id}>
                  {elem.title}
                </option>
              ))}
          </select>
          <button
            className="button-1"
            onClick={handleStart}
            disabled={isTimerRunning}
          >
            Start
          </button>
          <button
            onClick={handleStop}
            disabled={!isTimerRunning}
          >
            Stop
          </button>
        </div>
        <div className="project-timer-div-2">
          <p>
            {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default CalendarHeader;
