export const handleNumberFormat = (event) => {
    const { name, value } = event.target;
    console.log(event.which);

    const allowedKeys = [8, 46];
    const numberKeys = Array.from({ length: 10 }, (_, i) => 48 + i); 

    const isNumberKey = numberKeys.includes(event.which);
    const isAllowedKey = allowedKeys.includes(event.which);
    if (event.shiftKey) {
        event.preventDefault();
        return false;
    }

    if (isNumberKey || isAllowedKey) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
};

export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidEmail = (email) => {
  return emailPattern.test(email);
};

