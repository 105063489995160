import React from "react";
import "./Currency.css";
import { useState } from "react";
import { AddCurrency } from "../../services/Currency.service";
import { Button, Card } from "@mui/material";

const Currency = () => {
  const [nzd, setNzd] = useState({ n_usd: 0, n_aud: 0, n_gbp: 0 });
  const [usd, setUsd] = useState({ u_nzd: 0, u_aud: 0, u_gbp: 0 });
  const [aud, setAud] = useState({ a_usd: 0, a_nzd: 0, a_gbp: 0 });
  const [gbp, setGbp] = useState({ g_usd: 0, g_aud: 0, g_nzd: 0 });

  const { n_usd, n_aud, n_gbp } = nzd;
  const { u_nzd, u_aud, u_gbp } = usd;
  const { a_usd, a_nzd, a_gbp } = aud;
  const { g_usd, g_aud, g_nzd } = gbp;

  const nzd_handleChange = (event) => {
    setNzd({ ...nzd, [event.target.name]: event.target.value });
  };
  const aud_handleChange = (event) => {
    setAud({ ...aud, [event.target.name]: event.target.value });
  };
  const gbp_handleChange = (event) => {
    setGbp({ ...gbp, [event.target.name]: event.target.value });
  };
  const usd_handleChange = (event) => {
    setUsd({ ...usd, [event.target.name]: event.target.value });
  };

  return (
    <>
      <div className="curr_container">
        <div className="curr_mainDiv">
          <Card className="curr_div">
            <div>
              <form className="curr_form">
                <div className="curr_head">NZD Conversions</div>
                <div className="curr_inputs">
                  <label>USD:</label>
                  <input name="n_usd" value={n_usd} onChange={nzd_handleChange} />
                  <br />
                  <label>AUD:</label>
                  <input name="n_aud" value={n_aud} onChange={nzd_handleChange} />
                  <br />
                  <label>GBP:</label>
                  <input name="n_gbp" value={n_gbp} onChange={nzd_handleChange} />
                </div>
              </form>
              <div className="curr_btn">
                <Button variant="contained" size="small" sx={{ fontSize: "0.6rem" }}
                  onClick={() => {
                    AddCurrency({ aud: n_aud, gbp: n_gbp, usd: n_usd }, "nzd");
                  }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Card>
          <Card className="curr_div">
            <div>
              <form className="curr_form">
                <div className="curr_head">AUD Conversions</div>
                <div className="curr_inputs">
                  <label>USD:</label>
                  <input name="a_usd" value={a_usd} onChange={aud_handleChange} />
                  <br />
                  <label>AUD:</label>
                  <input name="a_nzd" value={a_nzd} onChange={aud_handleChange} />
                  <br />
                  <label>GBP:</label>
                  <input name="a_gbp" value={a_gbp} onChange={aud_handleChange} />
                </div>
              </form>
              <div className="curr_btn">
                <Button variant="contained" size="small" sx={{ fontSize: "0.6rem" }}
                  onClick={() => {
                    AddCurrency({ nzd: a_nzd, gbp: a_gbp, usd: a_usd }, "aud");
                  }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Card>
          <Card className="curr_div">
            <div>
              <form className="curr_form">
                <div className="curr_head">GBP Conversions</div>
                <div className="curr_inputs">
                  <label>USD:</label>
                  <input name="g_usd" value={g_usd} onChange={gbp_handleChange} />
                  <br />
                  <label>AUD:</label>
                  <input name="g_aud" value={g_aud} onChange={gbp_handleChange} />
                  <br />
                  <label>NZD:</label>
                  <input name="g_nzd" value={g_nzd} onChange={gbp_handleChange} />
                </div>
              </form>
              <div className="curr_btn">
                <Button variant="contained" size="small" sx={{ fontSize: "0.6rem" }}
                  onClick={() => {
                    AddCurrency({ aud: g_aud, nzd: g_nzd, usd: g_usd }, "gbp");
                  }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Card>
          <Card className="curr_div">
            <div>
              <form className="curr_form">
                <div className="curr_head">USD Conversions</div>
                <div className="curr_inputs">
                  <label>NZD:</label>
                  <input name="u_nzd" value={u_nzd} onChange={usd_handleChange} />
                  <br />
                  <label>AUD:</label>
                  <input name="u_aud" value={u_aud} onChange={usd_handleChange} />
                  <br />
                  <label>GBP:</label>
                  <input name="u_gbp" value={u_gbp} onChange={usd_handleChange} />
                </div>
              </form>
              <div className="curr_btn">
                <Button
                  variant="contained" size="small" sx={{ fontSize: "0.6rem" }}
                  onClick={() => {
                    AddCurrency({ aud: u_aud, gbp: u_gbp, nzd: u_nzd }, "usd");
                  }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Currency;



{/*
<div className="content-mainDiv">
        <div className="content-container">
          <div className="currency-container">
            <div className="grid-item">
              <form className="currency-form">
                <h2>NZD Conversions</h2>
                <label>USD:</label>
                <input name="n_usd" value={n_usd} onChange={nzd_handleChange} />
                <br />
                <label>AUD:</label>
                <input name="n_aud" value={n_aud} onChange={nzd_handleChange} />
                <br />
                <label>GBP:</label>
                <input name="n_gbp" value={n_gbp} onChange={nzd_handleChange} />
                <br />
              </form>
              <div>
                <button
                  className="currency-button"
                  onClick={() => {
                    AddCurrency({ aud: n_aud, gbp: n_gbp, usd: n_usd }, "nzd");
                  }}
                >
                  SAVE
                </button>
              </div>
            </div>
            <div className="grid-item">
              <form>
                <h2>AUD Conversions</h2>
                <label>USD:</label>
                <input name="a_usd" value={a_usd} onChange={aud_handleChange} />
                <br />
                <label>NZD:</label>
                <input name="a_nzd" value={a_nzd} onChange={aud_handleChange} />
                <br />
                <label>GBP:</label>
                <input name="a_gbp" value={a_gbp} onChange={aud_handleChange} />
                <br />
              </form>
              <div>
                <button
                  className="currency-button"
                  onClick={() => {
                    AddCurrency({ nzd: a_nzd, gbp: a_gbp, usd: a_usd }, "aud");
                  }}
                >
                  SAVE
                </button>
              </div>
            </div>
            <div className="grid-item">
              <form>
                <h2>GBP Conversions</h2>
                <label>USD:</label>
                <input name="g_usd" value={g_usd} onChange={gbp_handleChange} />
                <br />
                <label>AUD:</label>
                <input name="g_aud" value={g_aud} onChange={gbp_handleChange} />
                <br />
                <label>NZD:</label>
                <input name="g_nzd" value={g_nzd} onChange={gbp_handleChange} />
                <br />
              </form>
              <div>
                <button
                  className="currency-button"
                  onClick={() => {
                    AddCurrency({ aud: g_aud, nzd: g_nzd, usd: g_usd }, "gbp");
                  }}
                >
                  SAVE
                </button>
              </div>
            </div>
            <div className="grid-item">
              <form>
                <h2>USD Conversions</h2>
                <label>NZD:</label>
                <input name="u_nzd" value={u_nzd} onChange={usd_handleChange} />
                <br />
                <label>AUD:</label>
                <input name="u_aud" value={u_aud} onChange={usd_handleChange} />
                <br />
                <label>GBP:</label>
                <input name="u_gbp" value={u_gbp} onChange={usd_handleChange} />
                <br />
              </form>
              <div>
                <button
                  className="currency-button"
                  onClick={() => {
                    AddCurrency({ aud: u_aud, gbp: u_gbp, nzd: u_nzd }, "usd");
                  }}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
 */ }
