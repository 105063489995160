import dayjs from "dayjs";

export function getMonth(month = dayjs().month()) {
  month = Math.floor(month);
  const year = dayjs().year();

  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;

  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}

export function transposeArray(arr) {
  const rows = arr.length;
  const cols = arr[0].length;

  const result = [];

  for (let i = 0; i < cols; i++) {
    result[i] = [];
    for (let j = 0; j < rows; j++) {
      result[i][j] = arr[j][i];
    }
  }

  return result;
}

export function getWeek(currentDate) {
  // console.log(dayjs());
  // console.log(currentDate);
  // Get the current date

  // let currentDate = dayjs();
  // if(offset==='+'){
  //   currentDate=currentDate.add(7, 'day');
  // }
  // else if(offset==='-'){
  //   currentDate=currentDate.subtract(7, 'day');
  // }

  // Get the start of the current week (Sunday)
  const startOfWeek = currentDate.startOf("week");

  // Get the end of the current week (Saturday)
  const endOfWeek = currentDate.endOf("week");

  // Create an array to store the dates of the current week
  const datesOfWeek = [];

  // Loop from the start date to the end date and add each date to the array
  let currentDateOfWeek = startOfWeek;
  while (
    currentDateOfWeek.isBefore(endOfWeek) ||
    currentDateOfWeek.isSame(endOfWeek)
  ) {
    datesOfWeek.push(currentDateOfWeek.format("MM-DD-YYYY"));
    currentDateOfWeek = currentDateOfWeek.add(1, "day");
  }

  // console.log(datesOfWeek);

  return datesOfWeek;
}

export function getWeekDay(d) {
  // Create a Day.js object from a date string
  const date = dayjs(d); // Replace '2023-10-07' with your desired date

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const weekDay = date.day();

  // You can also get the day as a string (e.g., 'Sunday', 'Monday', etc.)
  const weekDayString = date.format("dddd");

  return weekDayString;
}
