export const glCodeList = [
    {
        id: Math.floor(Math.random() * 10000),
        value: "4000",
        title: "4000 - Franchise Sales",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4100",
        title: "4100 - Royaltie",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4105",
        title: "4105 - Master Franchise Royalties",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4110",
        title: "4110 - National Marketing",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4300",
        title: "4300 - Franchisee IT - Control Collected",
    },
    {
        id: Math.floor(Math.random() * 10000),
        title: "4600 - Meeting Fees",
        value: "4600",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4700",
        title: "4700 - Supplier Contributions",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4800",
        title: "4800 - Franchisee sales and branding material",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4850",
        title: "4850 - Franchisee IT - Collected",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4900",
        title: "4900 - Othere Revenue",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4950",
        title: "4950 - Monthly Traffic Loss Recovery",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "5095",
        title: "5095 - Franchise Recruitment",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "5055",
        title: "5055 - Induction, Training, Head Office Support",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "5100",
        title: "5100 - Franchisee sales and branding material – Spend",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "5200",
        title: "5200 - National Marketing - Traffic Staff",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "5300",
        title: "5300 - National Marketing - Other",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "5400",
        title: " 5400 - Master Franchise Royalties - Spend",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "5960",
        title: "5960 - Broker Fees, Commissions and franchisee rebates",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6005",
        title: "6005 - Accounting",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6100",
        title: "6100 - Bank Fees",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6115",
        title: "6115 - Cleaning Expenses",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6410",
        title: "6410 - Entertainment",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6105",
        title: "6105 - Freight and Postage",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6125",
        title: "6125 - General Office Expenses",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6128",
        title: "6128 - Insurance",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6151",
        title: "6151 - IT - Software Licences and Hosting",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6160",
        title: "6160 - IT - Development, system maintenance, user administration",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6010",
        title: "6010 - Legal",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6100 - ",
        title: "6100 - Payroll Related Levies",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6115",
        title: "6115 - Power and Water",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6130",
        title: "6130 -Printing and Stationery",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6140",
        title: "6140 - Rent - Office and Car Parks",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6152",
        title: "6152 - Telephone and Internet",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6200",
        title: "6200 - Vehicle Expenses",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6250",
        title: "6250 - Franchisee IT - Spent",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6300",
        title: "6300 - Wages and Salaries",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6306",
        title: "6306 - Conferences and Events",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6400",
        title: "6400 - Travel - National and Regional",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6405",
        title: "6405 - Travel - International",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4850",
        title: "4850 - Franchisee IT - Collected",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4950",
        title: "4950 - Franchisee POA Marketing - Collected",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6450",
        title: "6450 - Franchisee POA Marketing - Spent",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "4955",
        title: "4955 - Franchisee Marketing Other - Collected",
    },
    {
        id: Math.floor(Math.random() * 10000),
        value: "6650",
        title: "6650 - Franchisee Marketing Other - Spent F",
    },
];