import React, { useEffect, useState } from "react";
import "./ReadytosendtoXERO.css";
import { getSplitbillData } from "../../services/Currency.service";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../services/api.service";
import Spinner from "../../components/Spinner/Spinner";
import { Button, Paper } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from "@mui/system";

const ReadytosendtoXERO = () => {
  const navigate = useNavigate();
  const [splitbill, setSplitbill] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [rerender, setRerender] = useState(false);

  const fetchsplitBill = async () => {
    // let bills = await getSplitbillData();
    // setSplitbill(bills);

    setIsLoading(true);
    let bills = await getSplitbillData();
    setSplitbill(bills);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchsplitBill();
  }, []);

  // connect to xero
  const handleConnectToXero = async () => {
    let response = await fetchApi(`/api/xero/connect`);
    response = await response.json();
    window.open(response.consentUrl, "_blank");
  };

  // send invoice to xero
  const handleCreateInvoice = async (sId) => {
    try {
      setIsLoading(true);
      let response = await fetchApi(`/api/xero/invoices?invoiceid=${sId}`);
      response = await response.json();
      console.log(response);
      setIsLoading(false);
      if (response.success) {
        alert("invoice sent to xero");
      } else if (response.ind === "UA") {
        alert("connect to xero and try again");
      } else if (response.error) {
        alert(response.error);
      }
      fetchsplitBill();
      navigate("/home/readytosendtoxero");
      // setRerender(!rerender);
    } catch (err) {
      alert("Invoice send to Xero failed");
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          <Button
            sx={{ fontSize: "0.6rem" }} variant="outlined" size="small"
            onClick={handleConnectToXero}
          >
            Connect to Xero
          </Button>
          {splitbill.map((element, index) => {
            return element.bill.status === "AP" &&
              element.org_splits[0].rec_status === false ? (
              <Box component={Paper} className="readyToSentXero_bill" key={index}>
                <div className="table-details-bill">
                  <div className="table-details-bill-left">
                    <div className="outflowbill-invoice">
                      <span
                        className="pdfLogo-img">
                        <img
                          src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                          alt=""
                        />
                      </span>
                      <p className="outflow-invoice-number">
                        {element.bill.invoice_no}
                      </p>
                    </div>
                  </div>
                  <div className="table-details-bill-right">
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">From : </p>
                        <p className="tags1">{element.bill.vendor.name}</p>
                      </div>
                      <div className="field">
                        <p className="tags">To :</p>
                        <p className="tags1">{element.bill.organisation.name}</p>
                      </div>
                    </div>
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">PO Number :</p>
                        <p className="tags1">{element.bill.po_no}</p>
                      </div>
                      <div className="field">
                        <p className="tags">PO Amount :</p>
                        <p className="tags1">${element.bill.po_amount}</p>
                      </div>
                    </div>
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">Sub-Total :</p>
                        <p className="tags1">{element.bill.currency}</p>
                        <p className="tags1">${element.bill.subtotal}</p>
                      </div>
                      <div className="field">
                        <p className="tags">GL Code :</p>
                        <p className="tags1">{element.gl_code_rec}</p>
                      </div>
                    </div>
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">GST :</p>
                        <p className="tags1">{element.bill.currency}</p>
                        <p className="tags1">${element.bill.total_gst}</p>
                      </div>
                      <div className="field">
                        <p className="tags">Rule :</p>
                        <p className="tags1">{element.rule}</p>
                      </div>
                    </div>
                    <div className="field">
                      <div className="field">
                        <p className="tags">Total :</p>
                        <p className="tags1">{element.bill.currency}</p>
                        <p className="tags1">${element.bill.total_amt}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <TableContainer className="center-table-div" sx={{ '& .MuiTableCell-root': { fontSize: '10px', fontWeight:"600" } }}>
                  <Table size="small" sx={{margin:"0 8px"}} aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Invoice</TableCell>
                        <TableCell align="left">Company</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Split%</TableCell>
                        <TableCell align="left">GST</TableCell>
                        <TableCell align="left">Total (Exclusive GST.)</TableCell>
                        <TableCell align="left">Local(NZD)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {element.org_splits.map((orgSplit, idx) =>
                        <TableRow
                          key={orgSplit.name}
                        >
                          <TableCell component="th" scope="orgSplit">
                            {orgSplit.split_invoice_no}R
                          </TableCell>
                          <TableCell align="left">{orgSplit.organisation.name}</TableCell>
                          <TableCell align="left">{orgSplit.description}</TableCell>
                          <TableCell align="left">{orgSplit.split_per}%</TableCell>
                          <TableCell className="td-data">
                            {orgSplit.gst ? (
                              <span>&#10003;</span>
                            ) : (
                              // <>&#xd7;</>
                              <>&#88;</>
                            )}
                            &nbsp;&nbsp;&nbsp;
                            <span className="span-data">
                              {orgSplit.organisation.currency}
                              {orgSplit.gst_amount}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            {orgSplit.organisation.currency}
                            {orgSplit.gst_amount}
                          </TableCell>
                          <TableCell align="left">{orgSplit.local_nzd}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="send-to-xero-buttons">
                  <div className="send-to-xero-approved-button">Approved</div>
                  <Button sx={{ fontSize: "0.6rem" }} variant="contained" size="small" onClick={() => handleCreateInvoice(element.id)}>
                    Send To Xero
                  </Button>
                </div>
              </Box>
            ) : null;
          })}
        </>
      )}
    </div>
  );
};

export default ReadytosendtoXERO;
