import React, { useEffect, useReducer, useState } from "react";
import GlobalContext from "./GlobalContext";
import dayjs from "dayjs";
import { getTask } from "../../services/Currency.service";
import DirectorApprovalBill from "../DirectorApprovalBill/DirectorApprovalBill";

const ContextWrapper = (props) => {
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [dayIndex, setDayIndex] = useState(dayjs());
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEvent, setShowEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openTimeSheetModal, setOpenTimeSheetModal] = React.useState(false); /// add bill modal state variable
  const [value, setValue] = React.useState(0); /// tab state value
  const [selectedComponent, setSelectedComponent] = React.useState(<DirectorApprovalBill />);
  const [fetchData, setFetchData] = useState(false)
  // console.log("openTimeSheetModal", openTimeSheetModal)
  // const [savedEvents, setSavedEvents] = useState([]);

  // const fetchTask = async () => {
  //   let data = await getTask();
  //   setSavedEvents(data);
  //   console.log(".....", data);
  // };

  // useEffect(() => {
  //   // fetchTask().then(() => {
  //   //   console.log("SAVED EVENTS CONTEXT WRAPPER", savedEvents);
  //   // });
  // }, []);

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        dayIndex,
        setDayIndex,
        daySelected,
        setDaySelected,
        showEvent,
        setShowEvent,
        // savedEvents,
        // setSavedEvents,
        // dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        openTimeSheetModal,
        setOpenTimeSheetModal,
        value,
        setValue,
        selectedComponent, setSelectedComponent,
        fetchData, setFetchData
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default ContextWrapper;
