import React, { useEffect, useState } from "react";
import ProjectPercent from "../ProjectPercent/ProjectPercent";
import "./ProjectForm.css";
import { AddnewProject } from "../../services/Currency.service";
import { fetchApi, validateTaxRates } from "../../services/api.service";
import ProjectStaff from "../ProjectStaff/ProjectStaff";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import { glCodeList } from "../../Data/data";

const initvalues = {
  project_title: null,
  cost: null,
  description: null,
  category: null,
  gl_code: null,
  start_date: null,
  end_date: null,
  color: null,
  project_percent: [],
  project_staff: [],
  "user_id": ''
};

const ProjectForm = ({ handleClose, user }) => {
  initvalues.user_id = user;
  const [addProject, setaddProject] = useState(initvalues);
  const [orgs, setOrgs] = useState([]);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null);
  const [MsgColor, setMsgColor] = useState(null)

  const {
    project_title,
    cost,
    description,
    category,
    start_date,
    end_date,
  } = addProject;

  const handleChange = (e) => {
    e.preventDefault();
    setaddProject({ ...addProject, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmit(true)
    let taxRateArr = addProject.project_percent.map(item => item["project_per"])
    // validating tax rate or split percentage
    let validationResult = validateTaxRates(taxRateArr);
    if (!validationResult.isValid) {
      setApiMsg(validationResult.message);
      setMsgColor("red")
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      setIsSubmit(false)
      return;
    }

    const formattedData = {
      ...addProject,
      user_id: user,
    };

    if (formattedData?.project_title?.length > 0 && formattedData?.gl_code?.length > 0) {
      await AddnewProject(formattedData).then((res) => {
        if (res.err) {
          setOpenSnackbar(true)
          setApiMsg(res?.err)
          setIsSubmit(false)
        } else if (res.success === true) {
          setApiMsg(res?.message)
          setOpenSnackbar(true)
          setIsSubmit(false)
        }
      }).catch((err) => {
        console.log(err)
        setOpenSnackbar(true)
        setApiMsg("something went wrong!")
        setIsSubmit(false)
      })
    } else {
      setOpenSnackbar(true)
      setApiMsg("Project Title and GL code must be filled")
      setIsSubmit(false)
    }
    // await AddnewProject(formattedData).then((result) => {
    setTimeout(() => { setOpenSnackbar(false) }, [1500])
    setTimeout(() => { handleClose() }, [1800])
  };

  const getOrg = async () => {
    const urlOrg = await fetchApi("/api/orgs");
    const orglist = await urlOrg.json();
    setOrgs(orglist.palyload);
  };

  useEffect(() => {
    getOrg();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <><div className="addProjectform-container">
      <div>
        <div className="flex">
          <input
            onChange={handleChange}
            type="text" required
            name="project_title"
            value={project_title}
            placeholder="Project Title*" />
          <input
            onChange={handleChange}
            type="number"
            name="cost"
            value={cost}
            placeholder="$ Budget" />
        </div>
        <div className="flex">
          <input
            onChange={handleChange}
            type="text" required
            name="description"
            value={description}
            placeholder="Description" />
          <select name="gl_code" label="GL-Code" onChange={(e) => handleChange(e)} MenuProps={MenuProps}>
            <option>GL Code*</option>
            {glCodeList?.map((item) => {
              return <option key={item?.id} value={item?.value}>{item?.title}</option>
            })}
          </select>
        </div>
        <div className="box-1">
          <select
            onChange={(e) => handleChange(e)}
            type="text"
            name="category"
            value={category}
          >
            <option>Category</option>
            <option value="Cost Recovery">Cost Recovery</option>
          </select>
          <input
            placeholder="Start Date"
            type="text"
            onFocus={(e) => {
              e.target.type = "date";
            }}
            onBlur={(e) => {
              e.target.type = "text";
            }}
            onChange={handleChange}
            name="start_date"
            value={start_date} />
          <input
            placeholder="End Date"
            type="text"
            onFocus={(e) => {
              e.target.type = "date";
            }}
            onBlur={(e) => {
              e.target.type = "text";
            }}
            onChange={handleChange}
            name="end_date"
            value={end_date} />
        </div>

        <div className="addPro-plus-section">
          <div id="split-add">
            Assigned Staff:
            <ProjectStaff
              addProject={addProject}
              setaddProject={setaddProject} />
          </div>

          <div id="split-add">
            Split by Company %:
            <ProjectPercent
              addProject={addProject}
              setaddProject={setaddProject} />
          </div>
        </div>

        <div className="outflowform-button-submit">
          <Button
            onClick={handleClose}
            size="small"
            variant="outlined"
            sx={{ fontSize: "0.6rem" }}
          >
            Cancel
          </Button>

          <LoadingButton
            size="small"
            variant="contained"
            sx={{ fontSize: "0.6rem" }}
            onClick={handleSubmit}
            loading={isSubmit}
            loadingPosition="end"
          >
            Confirm
          </LoadingButton>
        </div>
      </div>
    </div>
      <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={MsgColor} />
    </>
  );
};
export default ProjectForm;