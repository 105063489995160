import React, { useEffect, useState } from "react";
import {
  GetUsers,
  getOrgInvoiceUrl,
} from "../../services/Currency.service";
import { fetchApi } from "../../services/api.service";
import Spinner from "../../components/Spinner/Spinner";
import DashboardNav from "../DashboardNew/DahsboardNew";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export const DirectorReceivableInvoices = ({ user }) => {
  const [recInvoice, setRecInvoice] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getinvoicesfromXERO = async () => {
    setIsLoading(true);
    console.log("use effect called");
    const orgUser = await GetUsers();

    const [currUserDetails] = orgUser?.filter((elem) => elem?.id === user?.id);
    const currUserAllOrg = currUserDetails?.organisations;
    // console.log(currUserAllOrg);

    const rInvoices = [];

    for (let i = 0; i < currUserAllOrg?.length; i++) {
      // console.log("test log", currUserAllOrg[i].name);
      // const res = await GetInvoiceOfXero(currUserAllOrg[i].name, "R");
      const data = await fetchApi(
        `/api/xero/ginvoices?org_from=${currUserAllOrg[i]?.name}&type=R`
      );
      const response = await data.json();
      console.log(response);
      const res = response.payload;
      rInvoices.push(...res);
      console.log(res);
      setIsLoading(false);
    }
    setRecInvoice(rInvoices);
  };

  useEffect(() => {
    getinvoicesfromXERO();
  }, []);

  const handleGetBillFile = async (invoice_no) => {
    const url = await getOrgInvoiceUrl(invoice_no);
    if (url) window.open(url, "_blank");
  };
  return (
    <div>
      <div className="readytosendtoXERO-box1">
        {/* <DirectorNavbar /> */}
        {/* <OutflowNavbar/> */}
        {/* <DashboardNav/> */}

      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="outflowapproval-container-box">
            {/* {recInvoice.length > 0 ? ( */}
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice</TableCell>
                    <TableCell>Invoice No.</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>GL Code</TableCell>
                    <TableCell>Invoice Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recInvoice.map((e, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <div className="outflowbill-invoice">
                            <p
                              className="pdfLogo-img"
                              onClick={() => handleGetBillFile(e.invoice_no)}
                            >
                              <img
                                src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                                alt=""
                              />
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>{e.invoice_no}</TableCell>
                        <TableCell>{e.org_from}</TableCell>
                        <TableCell>{e.org_to}</TableCell>
                        <TableCell>{e.gl_code}</TableCell>
                        <TableCell>{e.amount}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* ) : (
              <div>LOADING...</div>
            )} */}
          </div>
        </>
      )}
    </div>
  );
};
