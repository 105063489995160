import { Dialog, DialogTitle } from '@mui/material'
import React from 'react'
import RefusalPage from '../RefusalPage/RefusalPage'

const RefusalDialogForm = (props) => {
    const { open, setOpenTimeSheetModal, refusedObjData } = props;

    const handleClose = () => {
        setOpenTimeSheetModal(false)
    };

    return (
        <>
            <Dialog onClose={handleClose} open={open} fullWidth={"fullWidth"} maxWidth={"sm"}>
                <DialogTitle>Refusal Bill Split</DialogTitle>
                <RefusalPage refusedObjData={refusedObjData} handleClose={handleClose} />
            </Dialog>
        </>
    )
}

export default RefusalDialogForm
