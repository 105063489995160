import { useState } from "react";
import ReadytosendtoXERO from "../ReadytosendtoXERO/ReadytosendtoXERO";
import { SenttoXero } from "../SenttoXero/SenttoXero";
import SendtoxeroPayInv from "../SendtoxeroPayInv/SendtoxeroPayInv";
import { Button } from "@mui/material";


const NewXero = () => {
  const [selectedComponent, setSelectedComponent] = useState(<ReadytosendtoXERO />);

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };

  return (
    <>
      <div className="outflownavbar-container">
        <Button
          sx={{ textDecoration: "none", fontSize: "0.6rem", color: selectedComponent?.type?.name === "ReadytosendtoXERO" ? "white" : "#146EF5" }} size="small" variant={selectedComponent?.type?.name === "ReadytosendtoXERO" ? "contained" : null}
          onClick={() => handleComponentChange(<ReadytosendtoXERO />)}
        >
          Send to Xero(Rec. Inv)
        </Button>
        <Button
          sx={{ textDecoration: "none", fontSize: "0.6rem", color: selectedComponent?.type?.name === "SendtoxeroPayInv" ? "white" : "#146EF5" }} size="small" variant={selectedComponent?.type?.name === "SendtoxeroPayInv" ? "contained" : null}
          onClick={() => handleComponentChange(<SendtoxeroPayInv />)}
        >
          Send to Xero(Pay. Inv)
        </Button>
        <Button
          sx={{ textDecoration: "none", fontSize: "0.6rem", color: selectedComponent?.type?.name === "SenttoXero" ? "white" : "#146EF5" }} size="small" variant={selectedComponent?.type?.name === "SenttoXero" ? "contained" : null}
          onClick={() => handleComponentChange(<SenttoXero />)}
        >

          Sent to Xero
        </Button>
      </div>
      {selectedComponent}
    </>
  );
};

export { NewXero };
