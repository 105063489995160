import React from "react";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddEmployee from "../Employee/AddEmployee";
import AddOrg from "../Org/AddOrg";

const Modal = (props) => {
  const { open, onClose, data, isUpdate, updateData, getAllUser } = props;
  console.log(data, "data");
  const handleClose = () => {
    onClose(); // Call onClose function passed from parent component
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <IconButton
        onClick={handleClose}
        style={{ position: "absolute", right: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {data == "employee" ? (
            <AddEmployee
              onClose={handleClose}
              isUpdate={isUpdate}
              updateData={updateData}
              getAllUser={getAllUser}
            />
          ) : (
            <AddOrg />
          )}
        </div>
      </DialogTitle>
    </Dialog>
  );
};

export default Modal;
