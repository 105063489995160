import React from "react";
import "./TimesheetNavbar.css";
import DirectorsView from "../DirectorsView/DirectorsView";
import TimesheetReports from "../TimesheetReports/TimesheetReports";
import { Button, Paper } from "@mui/material";
import Timesheet from "../Timesheet/Timesheet";
import { Box } from "@mui/system";

const TimesheetNavbar = ({ user }) => {
  const [selectedComponent, setSelectedComponent] = React.useState(<Timesheet user={user} />);

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };
  return (
    <div className="TimesheetNav-mainDiv">
      <Box component={Paper} className="TimesheetNav-Container">
        <div className="outflownavbarTimesheet-container">
          <div className="outflownavbar-container-divitemsize">
            <Button
              style={{ textDecoration: "none", fontSize: "0.6rem", color: selectedComponent?.type?.name === "Timesheet" ? "white" : "#146EF5" }}
              onClick={() => handleComponentChange(<Timesheet user={user} />)} variant={selectedComponent?.type?.name === "Timesheet" ? "contained" : null}
            >
              Weekly Timesheet
            </Button>
          </div>
          <div className="outflownavbar-container-divitemsize">
            <Button
              style={{ textDecoration: "none", fontSize: "0.6rem", color: selectedComponent?.type?.name === "TimesheetReports" ? "white" : "#146EF5" }}
              onClick={() => handleComponentChange(<TimesheetReports user={user} />)} variant={selectedComponent?.type?.name === "TimesheetReports" ? "contained" : null}
            >
              Timesheet Report
            </Button>
          </div>
          {/* {user?.role === 2 && <div className="outflownavbar-container-divitemsize">
            <Button
              style={{ textDecoration: "none", fontSize: "0.6rem", color: selectedComponent?.type?.name === "DirectorsView" ? "white" : "#146EF5" }}
              onClick={() => handleComponentChange(<DirectorsView user={user} />)} variant={selectedComponent?.type?.name === "DirectorsView" ? "contained" : null}
            >
              Director Bill View
            </Button>
          </div>} */}
        </div>
        {/* Render selected component */}
        {selectedComponent}
      </Box>
    </div>
  );
};

export default TimesheetNavbar;
