import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../TimesheetContext/GlobalContext";
import "./Day.css";
import "./Events.css";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { getWeekDay } from "../util/util";
import { getTask, getUserTasks } from "../../services/Currency.service";

const Day = ({ day, rowIdx, user }) => {
  const stylesCurrent = {
    backgroundColor: "#0056b3",
    color: "#fff",
    borderRadius: "50%",
    width: "1.75rem",
    height: "1.75rem",
    textAlign: "center",
    lineHeight: "1.75rem",
  };

  function getCurrentDayClass() {
    // console.log("get the current day", dayjs().format("MM-DD-YYYY"));
    // console.log("D-A-Y-J-S", dayjs());
    // console.log("D-A-Y-J-S", day);

    return day === dayjs().format("MM-DD-YYYY") ? stylesCurrent : {};
  }

  const getStyles = getCurrentDayClass();
  const [dayEvents, setDayEvents] = useState([]);
  const {
    setDaySelected,
    setShowEvent,
    // savedEvents,
    setSelectedEvent,
    setOpenTimeSheetModal, fetchData
  } = useContext(GlobalContext);

  const [savedEvents, setSavedEvents] = useState([]);

  const fetchTask = async () => {
    let data = await getUserTasks(user?.id);
    setSavedEvents(data);
    // console.log(".....", data);
  };

  useEffect(() => {
    fetchTask().then(() => { });
  }, [fetchData]);

  useEffect(() => {
    const events = savedEvents?.filter((evt) => {
      return dayjs(evt.day).format("MM-DD-YYYY") === day;
    });
    setDayEvents(events);
  }, [savedEvents, day]);

  const showAddIcon = () => {
    if (dayjs(day).isAfter(dayjs().format("MM-DD-YYYY"))) {
      return false;
    }
    return true;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <header
        style={{
          border: "1px solid #ECECEC",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: "0.875rem",
            marginTop: 1
          }}
        >
          {getWeekDay(day)}
        </p>
        <div style={{ display: "flex" }}>
          <p style={getStyles}>{day.split("-")[1]}</p>
        </div>
        {showAddIcon() ? (
          <div
            onClick={() => {
              setDaySelected(day);
              setShowEvent(true);
              setSelectedEvent(null);
              setOpenTimeSheetModal(true)
            }}
            className="add-button-container"
          >
            <button className="add-button">
              <AddRoundedIcon />
            </button>
          </div>
        ) : (
          ""
        )}
      </header>

      <div
        onClick={() => {
          setDaySelected(day);
          setShowEvent(true);
          setOpenTimeSheetModal(true)
        }}
        className="new-event-tag"
      >
        {dayEvents?.map((evt, idx) => (
          <div
            key={idx}
            id="event-tag-title"
            className={`bg-${evt.label}-500`}
            onClick={() => {
              setSelectedEvent(evt);
            }}
          >
            {evt.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Day;
