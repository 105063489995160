import React, { useState } from "react";
import DirectorPayableInvoices from "../DirectorPayableInvoices/DirectorPayableInvoices";
import { Button } from "@mui/material";
import { DirectorReceivableInvoices } from "../DirectorReceivableInvoices/DirectorReceivableInvoices";

const DashboardNew = () => {
  const [selectedComponent, setSelectedComponent] = useState(<DirectorPayableInvoices />);

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };
  return (
    <div className="">
      <div className="outflownavbar-container">
        <div className="outflownavbar-container-divitemsize">
          <Button
            style={{ textDecoration: "none", fontSize: "0.6rem", color:selectedComponent?.type?.name === "DirectorPayableInvoices" ? "white" : "#146EF5"}}
            variant={selectedComponent?.type?.name === "DirectorPayableInvoices" ? "contained" : null}
            onClick={() => handleComponentChange(<DirectorPayableInvoices />)}
          >
            Payable Invoices
          </Button>
        </div>
        <div className="outflownavbar-container-divitemsize">
          <Button
            style={{ textDecoration: "none", fontSize: "0.6rem", color:selectedComponent?.type?.name === "DirectorReceivableInvoices" ? "white" : "#146EF5" }}
            variant={selectedComponent?.type?.name === "DirectorReceivableInvoices" ? "contained" : null}
            onClick={() => handleComponentChange(<DirectorReceivableInvoices />)}
          >
            Receivable Invoices
          </Button>
        </div>
      </div>
      {/* Render selected component */}
      {selectedComponent}
    </div>
  );
};

export default DashboardNew;
